import React from 'react';
import ReloadIcon from 'components/icon/reload-icon';
import styles from './style.module.scss';
import { testIds } from 'constants/test-constants';

interface Props {
  className?: string;
}

export default function LoadingSpinner({ className = '' }: Props) {
  return (
    <div className={`${styles.component} ${className}`} data-testid={testIds.loadingSpinner}>
      <ReloadIcon />
    </div>
  );
}
