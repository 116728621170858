import { generateRouteMatchObjectFromPath } from 'components/dynamic-page/route-matchers';
import { getPositionFromDescription } from 'utils/google-maps/getPositionFromDescription';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import { captureLastSearch } from 'pages/api/last-search/lastSearchApiClient';
import getTargetedUrl from 'components/dynamic-page/new-area-listings-page/targeted-url-helper';
import { MapType } from 'data/search-predictions';

import type { SearchSuggestions } from 'components/suggested-location-dropdown';
import type ListingParams from 'contexts/preferences/listing-params';
import type { NextRouter } from 'next/router';
import type { Map } from '@zoocasa/node-kit/map/get-map';
import type { User } from 'contexts';
import type { Themes } from 'types/themes';

export default async function pushToRoute(
  searchSuggestion: SearchSuggestions,
  listingParams: ListingParams,
  router: NextRouter,
  themeName: Themes,
  map?: MapType,
  useUsListings = false,
  user?: User
) {
  // this function only handles static data in data.json in suggested-location-dropdown
  await navigateToSearchSuggestion(searchSuggestion, listingParams, router.asPath, router.push.bind(router), themeName, map?.changeLocation?.bind(map), useUsListings, user);
}

export async function navigateToSearchSuggestion(
  searchSuggestion: SearchSuggestions,
  listingParams: ListingParams,
  currentPathName: string,
  pushToRoute: NextRouter['push'],
  themeName: Themes,
  changeMapLocation?: Map['changeLocation'],
  useUsListings = false,
  user?: User
) {
  const isSearchPage = currentPathName.startsWith('/search');
  const { zoom, urlPath, hasAreaPage, label } = searchSuggestion;
  let latitude = searchSuggestion.latitude;
  let longitude = searchSuggestion.longitude;
  if (!latitude || !longitude) {
    const coordinates = await getPositionFromDescription(label, useUsListings);
    latitude = coordinates?.latitude || 0;
    longitude = coordinates?.longitude || 0;
  }
  listingParams.setZoom(zoom);
  listingParams.setLatitude(latitude);
  listingParams.setLongitude(longitude);

  if (isSearchPage) {
    listingParams.setAreaName(searchSuggestion.label.split(' -')[0]);
    if (searchSuggestion.urlPath) {
      listingParams.setSlug(urlPath.split('-')[0] + '-' + urlPath.split('-')[1]);
    }
    changeMapLocation?.({ lng: longitude, lat: latitude }, zoom);
  } else {
    if (hasAreaPage) {
      const routeMatchObject = generateRouteMatchObjectFromPath(urlPath);
      const slug = `${routeMatchObject.city}-${routeMatchObject.provinceCode}`;
      listingParams.setSlug(slug);
      const areaPageUrl = `${window.location.origin}/${urlPath}`;
      const areaPageUrlWithFilters = getTargetedUrl({ url: areaPageUrl, filters: listingParams.filter, sort: listingParams.sort, pageNumber: 1 });
      pushToRoute(areaPageUrlWithFilters);
    } else {
      pushToRoute({ pathname: `/search?${formatQueryIntoURLQueryParams(listingParams.filter)}&sort=${listingParams.sort}` });
    }
  }

  if (user?.id && listingParams) {
    captureLastSearch({ userId: user.id, lastSearch: listingParams, sourceTheme: themeName });
  }
}