import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ReloadIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="33" viewBox="0 0 29 33" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero" transform="translate(-922 -1394)">
          <g transform="rotate(90 435 885)">
            <g transform="translate(944 369)">
              <path d="M2.618 11.7L.123 6.834a1.114 1.114 0 01.052-1.109 1.105 1.105 0 011.913.09l1.303 2.548A14.54 14.54 0 0117.673.044c6.02.47 11.125 4.623 12.83 10.436a1.103 1.103 0 01-2.12.61 12.352 12.352 0 00-10.947-8.851A12.335 12.335 0 005.334 9.417l3.62-1.263c.373-.131.787-.052 1.086.206a1.111 1.111 0 01-.357 1.888l-5.73 2.038a1.375 1.375 0 01-.352 0c-.411.003-.79-.223-.983-.587zm30.269 10.437l-2.484-4.92a1.102 1.102 0 00-1.358-.499l-5.718 2.017a1.11 1.11 0 00-.679 1.413c.201.578.832.883 1.408.681l3.631-1.274a12.302 12.302 0 01-12.132 7.231 12.32 12.32 0 01-10.928-8.96 1.103 1.103 0 00-2.12.599c1.66 5.853 6.771 10.053 12.816 10.53 6.044.477 11.746-2.87 14.296-8.392l1.303 2.56c.187.372.567.608.982.61a1.1 1.1 0 00.497-.123 1.11 1.11 0 00.486-1.473z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ReloadIcon;
