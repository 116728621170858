import { AutocompletePredictionPredictionCategory } from './types';

/**
 * Classifies a Google Places Autocomplete prediction based on its types.
 * 
 * The function examines the array of `types` provided in the prediction result and classifies the prediction
 * into one of the following categories:
 * 
 * - `'street'`: If the prediction includes `street_address`, `premise`, or `route`, which represent streets, specific buildings, or parts of buildings.
 * - `'city'`: If the prediction includes `locality`, which represents a city or town.
 * - `'neighborhood'`: If the prediction includes `sublocality` or `neighborhood`, which represent neighborhoods or districts within cities.
 * - `'other'`: If none of the above categories match.
 * 
 * @param types - An array of type strings from the Google Places Autocomplete prediction.
 *                These types indicate the nature of the prediction (e.g., address, locality, establishment).
 * 
 * @returns A `PredictionCategory` indicating whether the prediction is a city, neighborhood, street, or other.
 * 
 * @see {@link https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types Google Maps Geocoding API Types}
 * 
 */ 
export const getPredictionCategory = (types: string[]): AutocompletePredictionPredictionCategory => {
  switch (true) {
  // Street-level predictions
  case types.includes('street_address') || types.includes('premise') || types.includes('route'):
    return AutocompletePredictionPredictionCategory.STREET;
  
    // City predictions
  case types.includes('locality'):
    return AutocompletePredictionPredictionCategory.CITY;
  
    // Neighborhood predictions
  case types.includes('sublocality') || types.includes('neighborhood'):
    return AutocompletePredictionPredictionCategory.NEIGHBORHOOD;
  
  default:
    return AutocompletePredictionPredictionCategory.CITY;
  }
};