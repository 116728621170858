import { useRouter } from 'next/router';
import { MapType } from 'data/search-predictions';

const useMapLocationChanger = (): MapType => {
  const router = useRouter();
  const map: MapType = {
    changeLocation(location, zoom) {
      router.push(
        {
          pathname: '/search',
          query: { lat : location.lat, lgt: location.lng, zoom },
        },
        '/search',
        { shallow: true }
      );
    },
  };
  return map;
};

export default useMapLocationChanger;